import {
  ageDecadeFormatter,
  genderI18n,
  getReviewImagePath
} from '@lifedot/utils'
import dayjs from 'dayjs'
import { Rating } from '@lifedot/atoms/Rating'
import { FC, Fragment } from 'react'
import { Voices_reviews$data } from './__generated__/Voices_reviews.graphql'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Image from 'next/image'

const styles = {
  voice: css({
    padding: 24,
    border: `1px solid ${palette.gray2}`,
    position: 'relative',
    [mq('sp')]: {
      padding: 16
    }
  }),
  header: css({
    display: 'flex',
    alignItems: 'center'
  }),
  reviewerInfo: css({
    marginLeft: 8,
    [mq('sp')]: {
      marginLeft: 4
    }
  }),
  age: css({
    fontWeight: 'bold'
  }),
  total: css({
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    '> :first-of-type': {
      marginLeft: 8
    }
  }),
  summary: css({
    display: 'flex',
    alignItems: 'center',
    [mq('sp')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }),
  date: css({
    color: palette.gray6,
    border: `1px solid ${palette.gray3}`,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: '4px 8px',
    [mq('sp')]: {
      top: 16,
      right: 16
    },
    [mq('max', 320)]: {
      top: 8,
      right: 8
    }
  }),
  content: css({
    borderTop: `1px solid ${palette.gray2}`,
    marginTop: 16,
    paddingTop: 16
  }),
  list: css({
    dt: {
      display: 'flex',
      justifyContent: 'space-between',
      ':not(:first-of-type)': {
        marginTop: 16
      }
    },
    dd: {
      marginTop: 4
    }
  }),
  caption: css({
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    fontWeight: 'bold'
  }),
  number: css({
    marginLeft: 8,
    color: palette.main03
  })
}

interface VoiceProps {
  age: number
  gender: string
  postAt: string
  score: number
  answers: Voices_reviews$data['reviews'][number]['answers']
}

export const Voice: FC<VoiceProps> = ({
  age,
  gender,
  postAt,
  score,
  answers
}) => {
  const purchasePrice = answers.find(({ code }) => code === 'price')?.text

  return (
    <div css={styles.voice}>
      <p css={[typography.textXS, styles.date]}>
        {dayjs(postAt).format('YYYY年M月')}回答
      </p>
      <div css={styles.header}>
        <Image
          src={getReviewImagePath({ age, gender })}
          width={52}
          height={52}
          alt={`${ageDecadeFormatter(age)}・${genderI18n(gender)}のイラスト`}
        />
        <div css={styles.reviewerInfo}>
          <p css={[typography.textS, styles.age]}>
            {ageDecadeFormatter(age)}・{genderI18n(gender)}
          </p>
          <div css={[typography.textS, styles.summary]}>
            <div css={styles.total}>
              総合評価
              <Rating point={score} />
            </div>
            {!!purchasePrice && (
              <div css={typography.textS}>
                購入価格：
                <b css={typography.textM}>{purchasePrice}</b>
              </div>
            )}
          </div>
        </div>
      </div>
      <div css={styles.content}>
        <dl css={styles.list}>
          {answers.map(
            ({ code, label, score, text }) =>
              code !== 'price' &&
              !!text?.length && (
                <Fragment key={code}>
                  <dt css={typography.textM}>
                    <div css={styles.caption}>
                      <Image
                        src={images[code] ?? ''}
                        width={20}
                        height={20}
                        alt={`${label}のアイコン`}
                      />
                      {label}
                      <span css={styles.number}>{score.toFixed(1)}</span>
                    </div>
                  </dt>
                  <dd css={typography.textM}>{text}</dd>
                </Fragment>
              )
          )}
        </dl>
      </div>
    </div>
  )
}

const images: Record<string, string> = {
  traffic: 'https://s3.lifedot.jp/uploads/ending/path/67888/traffic.png',
  equipment: 'https://s3.lifedot.jp/uploads/ending/path/67890/equipment.png',
  management: 'https://s3.lifedot.jp/uploads/ending/path/67889/management.png',
  facilities: 'https://s3.lifedot.jp/uploads/ending/path/67891/facilities.png'
}
