import { FC, useMemo } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { Voice } from './Voice'
import { Voices_reviews$key } from './__generated__/Voices_reviews.graphql'
import { orderReviews } from './dependencies'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { SeeMore } from '@lifedot/components/SeeMore'

interface VoicesProps {
  reviews: Voices_reviews$key
  size?: number
}

export const Voices: FC<VoicesProps> = ({ reviews, size }) => {
  const data = useFragment(fragment, reviews)
  const orderedReviews = useMemo(
    () =>
      size
        ? orderReviews(data.reviews).slice(0, size)
        : orderReviews(data.reviews),
    [data.reviews, size]
  )

  return (
    <SeeMore visibleHeight={2500} defaultFold={orderedReviews.length > 5}>
      <AddMarginWrapper spacing={2}>
        {orderedReviews.map(
          ({ answers, gender, age, created_at, average_score }, index) => (
            <Voice
              key={index}
              answers={answers}
              gender={gender}
              age={age}
              postAt={created_at}
              score={average_score}
            />
          )
        )}
      </AddMarginWrapper>
    </SeeMore>
  )
}

const fragment = graphql`
  fragment Voices_reviews on Cemetery {
    reviews {
      age
      answers {
        code
        label
        score
        text
      }
      average_score
      created_at
      gender
    }
  }
`
