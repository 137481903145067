/**
 * @generated SignedSource<<e631cfde296b8d1eecfc7d44b77733c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ReviewOverview_summary$data = {
  readonly review_summary: {
    readonly count: number
    readonly total_score: number | null
    readonly scores: ReadonlyArray<{
      readonly code: string
      readonly label: string
      readonly score: number | null
    }>
  }
  readonly ' $fragmentType': 'ReviewOverview_summary'
}
export type ReviewOverview_summary$key = {
  readonly ' $data'?: ReviewOverview_summary$data
  readonly ' $fragmentSpreads': FragmentRefs<'ReviewOverview_summary'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ReviewOverview_summary',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReviewSummary',
      kind: 'LinkedField',
      name: 'review_summary',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'count',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'total_score',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryReviewSummaryScore',
          kind: 'LinkedField',
          name: 'scores',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'code',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'label',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'score',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '94db74327f32c60a5df72971673b1038'

export default node
