/**
 * @generated SignedSource<<e7ed7c7f945ca7524909a4786bc4ee51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Voices_reviews$data = {
  readonly reviews: ReadonlyArray<{
    readonly age: number
    readonly answers: ReadonlyArray<{
      readonly code: string
      readonly label: string
      readonly score: number
      readonly text: string | null
    }>
    readonly average_score: number
    readonly created_at: string
    readonly gender: string
  }>
  readonly ' $fragmentType': 'Voices_reviews'
}
export type Voices_reviews$key = {
  readonly ' $data'?: Voices_reviews$data
  readonly ' $fragmentSpreads': FragmentRefs<'Voices_reviews'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'Voices_reviews',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CemeteryReview',
      kind: 'LinkedField',
      name: 'reviews',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'age',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryReviewAnswer',
          kind: 'LinkedField',
          name: 'answers',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'code',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'label',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'score',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'text',
              storageKey: null
            }
          ],
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'average_score',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'created_at',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'gender',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '7fbca131a6b3637db3d52236702de7c2'

export default node
