import { FC } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { ReviewOverview_summary$key } from './__generated__/ReviewOverview_summary.graphql'
import { Ratings } from './Ratings'
import { css } from '@emotion/react'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'
import { ChartDynamic } from './ChartDynamic'
import { useInView } from 'react-intersection-observer'

const styles = {
  overview: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [mq('max', WindowWidth.TABLET)]: {
      flexDirection: 'column',
      '> :not(:first-of-type)': {
        marginTop: 12
      }
    }
  }),
  dummy: css({
    width: 300,
    height: 200,
    flexShrink: 0
  })
}

interface ReviewOverviewProps {
  summary: ReviewOverview_summary$key
}

export const ReviewOverview: FC<ReviewOverviewProps> = ({ summary }) => {
  const [ref, inView] = useInView({ rootMargin: '500px', triggerOnce: true })
  const {
    review_summary: { scores, total_score: totalScore, count }
  } = useFragment(fragment, summary)

  return (
    <div css={styles.overview} ref={ref}>
      <Ratings count={count} scores={scores} totalScore={totalScore} />
      <div css={styles.dummy}>{inView && <ChartDynamic scores={scores} />}</div>
    </div>
  )
}

const fragment = graphql`
  fragment ReviewOverview_summary on Cemetery {
    review_summary {
      count
      total_score
      scores {
        code
        label
        score
      }
    }
  }
`
