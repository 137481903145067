import { FC, Fragment } from 'react'
import { ReviewOverview_summary$data } from './__generated__/ReviewOverview_summary.graphql'
import { Rating } from '@lifedot/atoms/Rating'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { mq, WindowWidth } from '@lifedot/styles/mediaQuery'

const styles = {
  rating: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  headline: css({
    fontWeight: 'bold'
  }),
  scores: css({
    display: 'flex',
    flexWrap: 'wrap',
    width: '70%',
    marginTop: 12,
    dt: {
      width: '50%'
    },
    dd: {
      width: '50%'
    },
    [mq('max', WindowWidth.TABLET)]: {
      display: 'none'
    }
  })
}

interface RatingsProps {
  count: ReviewOverview_summary$data['review_summary']['count']
  scores: ReviewOverview_summary$data['review_summary']['scores']
  totalScore: ReviewOverview_summary$data['review_summary']['total_score']
}

export const Ratings: FC<RatingsProps> = ({ count, scores, totalScore }) => {
  return (
    <div css={styles.rating}>
      <p css={[typography.textXL, styles.headline]}>
        総合評価<small>（{count}件）</small>
      </p>
      {totalScore && <Rating large point={totalScore} />}
      <dl css={styles.scores}>
        {scores.map(
          ({ label, score, code }) =>
            score && (
              <Fragment key={code}>
                <dt css={typography.textS}>{label}</dt>
                <dd>
                  <Rating point={score} />
                </dd>
              </Fragment>
            )
        )}
      </dl>
    </div>
  )
}
