import { FC } from 'react'
import { ChartProps } from './Chart'
import dynamic from 'next/dynamic'

export const ChartDynamic: FC<ChartProps> = ({ scores }) => {
  const Chart = dynamic<ChartProps>(() =>
    import('./Chart').then((mod) => mod.Chart)
  )
  return <Chart scores={scores} />
}
