import { Voices_reviews$data } from './__generated__/Voices_reviews.graphql'

export const orderReviews = (
  reviews: Voices_reviews$data['reviews']
): Voices_reviews$data['reviews'] => {
  return [...reviews].sort(({ answers: a }, { answers: b }) => {
    if (
      a.some(({ code, text }) => code === 'price' && (text?.length ?? 0) > 1) &&
      !b.some(({ code, text }) => code === 'price' && (text?.length ?? 0) > 1)
    )
      return -1
    if (
      !a.some(
        ({ code, text }) => code === 'price' && (text?.length ?? 0) > 1
      ) &&
      b.some(({ code, text }) => code === 'price' && (text?.length ?? 0) > 1)
    )
      return 1
    return JSON.stringify(a).length < JSON.stringify(b).length ? 1 : -1
  })
}
